import React, {useEffect, useState} from "react";
import './App.css';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: ${props => props.margin};
    padding-top: ${props => props.margin};
`

const days = [
    {day: 1, youtube: 'TYcNYzCNYLE'},
    {day: 2, youtube: 'ItqXHOc8V2Y'},
    {day: 3, youtube: 'q1JukcHUBz4'},
    {day: 4, youtube: 'yKE2XRGNs7Q'},
    {day: 5, youtube: 'KXQ6MsUOJMQ'},
    {day: 6, youtube: 'vEq9gA7HOBs'},
    {day: 7, youtube: 'pcF5wQz5V2A'},
    {day: 8, youtube: '8LV4ZNd4TGA'},
    {day: 9, youtube: 'TspdvMPRxBI'},
    {day: 10, youtube: 'J9LAk5DVOsI'},
    {day: 11, youtube: '7oa4egJH_JU'},
    {day: 12, youtube: 'i_HkUk0BOxw'},
    {day: 13, youtube: 'n1W17Z_zTag'},
    {day: 14, youtube: 'IC_YF2aX6dc'},
    {day: 15, youtube: 'Ma5RvuhGOf8'},
    {day: 16, youtube: 'LaScXOBwAOM'},
    {day: 17, youtube: 'STU6P9Uim6c'},
    {day: 18, youtube: 'MwJYmAwzbfo'},
    {day: 19, youtube: 'oiMOXraDktk'},
    {day: 20, youtube: 'vfPx6Q6BCYA'},
    {day: 21, youtube: 'PSmwndBUvMg'},
    {day: 22, youtube: 'qTCPCXZhTKM'},
    {day: 23, youtube: 'esrDzVyaq_4'},
    {day: 24, youtube: 'yt67qUvbSxE'}
]

const DayStyled = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  height: ${props => props.size};
  width: ${props => props.size};
  margin-right: ${props => props.margin};
  margin-bottom: ${props => props.margin};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/border.svg");
  border-radius: 9px;
  cursor: ${props => props.open ? 'pointer' : 'default'};
`

const Text = styled.span`
  padding-top: 20px;
  font-family: 'Khula', sans-serif;
  font-size: ${props => props.fontSize};
  color: ${props => props.open ? '#6a2132' : 'rgba(0,0,0,0.4)'};
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`

const Day = ({day, onPlay, ...props}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (day) {
            const date = new Date();

            const mDay = date.getDate();
            const month = date.getMonth() + 1;
            if (month === 12 && mDay >= day.day) {
                setOpen(true);
            } else {
//                setOpen(true);
                setOpen(false);
            }

        }
    })

    return <DayStyled {...props} onClick={() => open && onPlay(day)} open={open}>
        <Text fontSize={props.fontSize} open={open}>{day.day}</Text>

    </DayStyled>
}

const YouTubPlayStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`

const YouTubePlay = ({day, onClose, width}) => {
    const [opts, setOpts] = useState({
        height: width < 640 ? '' + Math.floor((width / 640) * 390) : '390',
        width: width < 640 ? '' + width : '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    });

    return <YouTubPlayStyled onClick={() => onClose()}><YouTube videoId={day.youtube} opts={opts}/></YouTubPlayStyled>
}

const App = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const [size, setSize] = useState(70);
    const [margin, setMargin] = useState(20);
    const [play, setPlay] = useState(null);
    const [fontSize, setFontSize] = useState(60);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        let minCount = 100;
        let minMargin = 100;
        let fSize = 0;
        let sMargin = 20;

        for (let size = 50; size <= 1000; size++) {
            const w = Math.floor((width - sMargin) / (size + sMargin));
            const newMargin = Math.floor((width - (w * size)) / (w + 1));
            const h = Math.floor((height - newMargin) / (size + newMargin));

            if (w * h >= 24) {
                if (w * h < minCount) {
                    minCount = w * h;
                    fSize = size;
                    minMargin = newMargin;
                }
                if (w * h === minCount) {
                    if (newMargin < minMargin) {
                        minMargin = newMargin;
                        fSize = size;
                    }
                }
            }
        }
        setSize(fSize);
        setMargin(minMargin);
        setFontSize(Math.min(fSize - 20, 60));
    }, [width, height])

    return (
        <>
            <Container margin={margin + "px"}>
                {days.map(day => {
                    return <Day key={day.day} day={day} size={size + "px"} margin={margin + "px"}
                                onPlay={(day) => setPlay(day)} fontSize={fontSize + "px"}/>
                })}
            </Container>
            {play && play.youtube &&
            <YouTubePlay day={play} width={width} height={height} onClose={() => setPlay(null)}/>}
        </>
    );
}

export default App;
